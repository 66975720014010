import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import Register from './pages/Register';
import Reset from './pages/Reset';
import ResetEmail from './pages/ResetEmail';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter( [
	{
		path: '/',
		element: <Register></Register>
	},
	{
		path: '/reset',
		element: <Reset></Reset>
	},
	{
		path: '/reset-email',
		element: <ResetEmail></ResetEmail>
	}
] );

ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
	<React.StrictMode> <RouterProvider router={ router }/> </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
