import { useEffect } from 'react';

export default function ResetEmail() {
	function registerUser( e ) {
		e.preventDefault();

		document.getElementById( 'form-container' ).classList.add( 'hidden' );
		document.getElementById( 'thank-you' ).classList.remove( 'hidden' );
	}

	useEffect( () => {

	}, [] );

	return ( <>
		<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto h-32 w-auto" src="/assets/logo.webp" alt="Your Company"/>
				<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Enter your email</h2>
				<small className="mt-4 text-center block">Please enter your email and we will send you instructions to reset your password.</small>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div id="form-container" className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form id="register-form" className="space-y-6" action="#" onSubmit={ ( e ) => {
						registerUser( e );
					} }>
						<div>
							<label htmlFor="email" className="block text-sm font-medium text-gray-700"> Your email </label>
							<div className="mt-1">
								<input id="email" name="email" type="email" autoComplete="current-password" required className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"/>
							</div>
						</div>

						<div>
							<button id="submit" type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 hover:disabled:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-no-drop">
								Submit
							</button>
						</div>
					</form>
				</div>

				<div id="thank-you" className="hidden">
					<div className="rounded-md bg-green-50 p-4 shadow">
						<div className="flex">
							<div className="flex-shrink-0">
								<svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
								</svg>
							</div>
							<div className="ml-3">
								<h3 className="font-medium text-green-800">Email sent</h3>
								<div className="mt-2 text-sm text-green-700">
									<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</> );
}
